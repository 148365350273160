$(document).ready(() => {


    //open and close menu
    function toggleMenu() {
        $("#menu-toggle").toggleClass('is-active');
        $('.menu').toggleClass('menu-open');
    }


    // Scroll function 
    function scrollWindow(offset, interval) {
        $('html, body').animate({
            scrollTop: offset
        }, interval, () => {
            return false;
        });
    }


    $('#menu-toggle').on('click', function () {
        toggleMenu();
    });


    // Scroll SPY

    $(".menu-content ul li a[href^='#']").on('click', function (e) {
        e.preventDefault();
        bodyScrollLock.clearAllBodyScrollLocks();
        toggleMenu();
        scrollWindow($(this.hash).offset().top, 600);
    });

    const headerPosition = $("header").position().top;

    // Scroll To Top
    $(window).scroll(function () {
        const scrollValue = $(this).scrollTop();
        const header = $("header");
        const scroll = $('#scroll');
        if (scrollValue > 10) {
            scroll.fadeIn();
            header.addClass("fixed-header");
        } else {
            scroll.fadeOut();
            header.removeClass("fixed-header");
        }

        const newPos = Math.max(0, headerPosition - scrollValue);
        const posInterPol = newPos / headerPosition;
        header.css("top", newPos);
        header.css("background-color", `rgba(32,46,59, ${1 - posInterPol})`);

    });

    $('#scroll').click(function () {
        scrollWindow(0, 300);
        return false;
    });

});